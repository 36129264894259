.section-thinkia {
  .title-landing-product-management {
    color: var(--Color-2, #FFF);
    text-align: start;
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 77.693px */
    letter-spacing: -1.413px;
  
  }
  
  /*start updated landing width logo changed*/
  img.logo-landing-product-management {
    width: 300px;
  }
  
  /*end landing width logo changed*/
  
  
  .content-landing-product-management span {
    color: #ffffff;
    /* Color para la parte de 'Product Management' */
  }
  
  @media (max-width: 767px) {
    .header-landing-product-management {
      height: 342px;
    }
  
    .content-landing-product-management {
      position: relative;
      z-index: 2;
      /* Asegura que el contenido esté por encima del overlay */
      right: 0px !important;
  
    }
  
    img.logo-landing-product-management {
      width: 220px;
    }
  
    .title-landing-product-management {
      text-align: center;
      font-size: 32px;
    }
  
    .form-landing-product-management {
      padding-top: 40px;
  
    }
  }
  
  /* start updated*/
  /*background-position: left left;*/
  .header-landing-product-managementPM {
    background-image: url('../img/china.jpg');
    background-size: cover;
    background-position: left left;
    background-repeat: no-repeat;
    position: relative;
    height: 818px;
  }
  
  
  /*background changed*/
  .overlay-landing-product-management {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a224ca3;
    z-index: 1;
  }
  
  /*end updated*/
  
  .content-landing-product-management {
    position: relative;
    z-index: 2;
    right: 120px;
  }
}
