.navdesk-container {
    position: relative;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
  }
  
  .navdesk-container.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f4f4f4; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    z-index: 9999;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-link {
    color: var(--Fondo, #07081C);
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.36px;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #00afff;
  }
  