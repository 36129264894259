.new-section-landing-product-management {
    color: white;
    /* Texto blanco */
    padding: 50px 0;
    background: radial-gradient(88.4% 54.13% at 128.68% 28.27%, #3E6380 0%, #07081C 100%);
    padding-bottom: 0;
    position: relative;
}
.landing-product-management-title{
    color: var(--Color-2, #FFF);
    font-family: Poppins;
    font-size: clamp(18px, 2vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.48px;

}
.image-sectionTwo-containerIA {
    border-radius: 3px;
    transform: scaleX(-1);
    object-fit: cover;
}
.img-fluid{
    width: 100%;
    height: auto;
}
.margin-separator {
    display: block;
    margin-top: 10px; /* Ajusta el valor según necesites */
}

.first-row-landing-product-management p {
    font-size: 16px;
    color: #bbb;
    /* Color del párrafo */

}
.figures-landing-product-management{
    color: var(--Color-5, #00AFFF);
    font-family: Poppins;
    font-size: 66px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 92.4px */
    letter-spacing: -1.32px;


}
p.weeks-landing-product-management{
    color: var(--Color-5, #00AFFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.32px;

}

.first-row-landing-product-management {
    margin-top: 30px;
}

.info-box {
    background-color: #001f3f;
    
    border-radius: 10px;
    color: #00d1ff;
    border-radius: 15px;
    border: 2px solid rgba(0, 175, 255, 0.32);
    box-shadow: 4px 4px 15px 0px rgba(255, 255, 255, 0.29);
    width: 200px;
    height: 185px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.info-box-number{
    padding: 5px 40px 0 40px;

}

.information-section {
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    background: rgba(255, 255, 255, 0.08);
    padding: 14px 12px 14px 12px;
    gap:12px;

}

.learnsections-groups{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap:10px;
}

.learn-information-title {
    color: #F5F5F5;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 44px */
    letter-spacing: -0.8px;

}
.text-information-section-inner{
    color: var(--Color-2, #FFF);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;

}

.learn-information-container {
    margin-top: 5rem;
}

.info-box p {
    margin: 0;
}

.second-row-landing-product-management h2 {
    color: white;
    font-size: 32px;
}

.goals-list {
    /* list-style-type: none; */
    padding-left: 0;
}

.goals-list li {
    background-color: #00264d;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #ffffff;
}

.triangle-divider {
    position: relative;
    width: 100%;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 200px solid white;
    margin-top: -1px;

}
@media (max-width: 767px) {
    .info-box {
        background-color: #001f3f;
        padding: 10px; 
        border-radius: 15px;
        color: #00d1ff;
        border: 2px solid rgba(0, 175, 255, 0.32);
        box-shadow: 4px 4px 15px 0px rgba(255, 255, 255, 0.29);
        width: 100px; 
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .learn-information-title{
        font-size: 20px;
    }
    .text-information-section-inner{
        font-size: 15px;
    }
    .figures-landing-product-management {
        font-size: 40px;
        line-height: 1;
        margin: 0;
    }

    p.weeks-landing-product-management {
        font-size: 12px;
        
    }

    .second-row-landing-product-management {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
    }

    .information-section {
        height: fit-content;
        padding: 12px 12px 12px 12px;
        min-height: 72px;;
    }
    .new-section-landing-product-management{
        padding: 30px 0;

    }

    .first-row-landing-product-management {
        padding-left: 20px;
        margin-top: 30px;
        padding-right: 20px;
    }
    .triangle-divider {
        position: relative;
        width: 100%;
        height: 0;
        border-left: 100vw solid transparent;
        border-bottom: 100px solid white;
        margin-top: -1px;
        top: 30px;
    }
    .img-fluid{
        width: 100%;
        height: auto;
    }
}

@media (min-width: 999px) and (max-width: 1181px) {

    .second-row-landing-product-management {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
   
}
  