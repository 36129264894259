.upcoming-section-main {
    background-color: #08091D;
    padding: 60px 0;
    position: relative;
}

.upcoming-section {
    background-color: #08091D;
    padding: 40px;
    border-radius: 10px;
    background: linear-gradient(149deg, rgba(7, 8, 28, 0.95) 46.42%, rgba(33, 37, 130, 0.95) 156.01%);
    box-shadow: 4px 4px 30px 0px rgba(0, 175, 255, 0.14);
    max-width: 1300px;
    margin: 0 auto;
    border: 1px solid rgba(0, 175, 255, 0.1);
}

.upcoming-section-title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: clamp(20px, 2vw, 28px);
    color: #F5F5F5;
    margin-bottom: 30px;
}

.upcoming-section-info {
    background-color: #00AFFF;
    border-radius: 8px;
    /* padding: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 102px;
    text-align: center;
}

/* Cajas de información */
.info-box-section {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: clamp(14px, 2vw, 16px);
    color: white;
    text-align: center;
    flex: 1;
    padding: 10px;
    min-width: 150px;
    margin: 10px;
}

.price-upcoming-label-section {
    color: var(--Color-2, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: clamp(16px, 2vw, 20px);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 22px */
    letter-spacing: -0.4px;
}

.price-upcoming-section {
    color: var(--Color-5, #00AFFF);
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 52.8px */
    letter-spacing: -0.96px;
    margin: 5px 0;
}

.bonus-upcoming-section {
    margin: 5px 0;
    font-size: 12px;
    color: #fff;
    line-height: 200%;
    font-family: Poppins;
}

.btn-info-section-upcoming {
    display: flex;
    width: 273px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 2px;
    background: var(--Color-2, #FFF);
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: clamp(14px, 2vw, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 17.6px */
    letter-spacing: -0.32px;

}

.btn-info-section:hover {
    background-color: #F5F5F5;
}

.details-upcoming-list-section {
    color: var(--Color-2, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: clamp(14px, 2vw, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    padding-left: 0;
    list-style-position: inside;
    text-align: left;
    margin-left: 80px;
}

.details-upcoming-list-section li {
    margin-bottom: 10px;
    padding-left: 0;
}


.triangle-divider-upcoming-section {
    position: relative;
    width: 100%;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 200px solid white;
    margin-top: -1px;
    top: 60px;
}

.upcoming-section-title-info {
    color: var(--Color-2, #FFF);
    font-family: Poppins;
    font-size: clamp(16px, 2vw, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 26.4px */
    letter-spacing: -0.48px;
}

.upcoming-text-information-inner {
    color: var(--Color-2, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 22px */
    letter-spacing: -0.4px;

}

.upcoming-section-title {
    color: var(--Color-2, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 44px */
    letter-spacing: -0.8px;

}

.upcoming-text-information-inner p {
    white-space: pre-line;
    margin: 0;
    font-weight: 600;
}

@media (max-width: 767px) {
    .upcoming-section {
        background-color: #08091D;
        padding: 40px;
        border-radius: 10px;
        background: linear-gradient(149deg, rgba(7, 8, 28, 0.95) 46.42%, rgba(33, 37, 130, 0.95) 156.01%);
        max-width: 400px;
        margin: 0 auto;

    }

    .upcoming-text-information-inner {
        font-size: 16px;

    }

    .details-upcoming-list-section {
        color: var(--Color-2, #FFF);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Poppins;
        font-size: clamp(14px, 2vw, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        padding-left: 0;
        list-style-position: inside;
        text-align: left;
        margin-left: 60px;
    }

    .upcoming-section-title {
        color: var(--Color-2, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        /* 22px */
        letter-spacing: -0.4px;

    }

    .price-upcoming-section {
        font-size: 32px;
    }

    .upcoming-section-title-info {
        margin-bottom: 15px;
    }

    .upcoming-section-main {
        background-color: #08091D;
        padding: 10px 0;
    }

    .triangle-divider-upcoming-section {
        position: relative;
        width: 100%;
        height: 0;
        border-left: 100vw solid transparent;
        border-bottom: 100px solid white;
        margin-top: -1px;
        top: 10px;
    }

    .upcoming-section-info {

        height: 265px;
        width: 245px;

    }

    .info-box-section {
        justify-content: center;
    }

    .btn-info-section-upcoming {
        width: 100%;
    }


}