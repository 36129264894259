.section-thinkia {
    .accordion-button-download-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .btn-3-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 125px;
    }
    
    .btn-download-animated-test {
        position: relative;
        height: 50px;
        border: none;
        cursor: pointer;
        transition: 0.3s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        display: flex;
        width: 273px;
        height: 48px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-radius: 2px;
        background: var(--Color-5, #00AFFF);
        color:#ffffff;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 17.6px */
        letter-spacing: -0.32px;
    
    }
    @media (max-width: 767px){
        .btn-download-animated-test {
    
            width: 100%;
    
        
        }
    
    }
    
    @media (max-width: 767px) {
        .btn-3-wrapper{
            width: 100%;
        }
    
    }
}
