h2 {
  font-family: 'Poppins';
  font-weight: 700;
  color: #333;
}

.module-title {
  font-size: 18px;
  color: #007bff;
  text-align: left;
  font-weight: 600;
}

.card-accordion {
  border-bottom: 1px solid rgba(7, 8, 28, 0.24);

}

.module-title.collapsed {
  color: #000;
}

.card-body {
  font-size: 14px;
  color: #555;
}

.btn-link {
  text-decoration: none;
}

.download-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}

.accordion-toggle-icon {
  cursor: pointer;
}

.module-title,
.module-number {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.module-number {
  color: #00AFFF;
  font-size: 24px;
}

.module-title {
  color: #07081C;
  font-size: 24px;
}

.accordion-toggle-icon svg {
  transition: transform 0.3s ease;
}

.collapse.show+.accordion-toggle-icon svg {
  transform: rotate(180deg);
}

.card-header-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.card-body-accordion {
  background: rgba(7, 8, 28, 0.04);
}

.accordion-content-title-inner {
  color: var(--Fondo, #07081C);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;

}

.accordion-title-desktop-version {
  color: var(--Fondo, #07081C);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.8px;

}

.Accordion-content-description-inner {
  /* color: rgba(7, 8, 28, 0.64);  */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.32px;
  list-style-type: disc; /* o 'circle', 'square', 'decimal', etc. */
  padding-left: 20px; /* Asegura suficiente espacio para los marcadores */
  margin-left: 0; /* Ajusta según sea necesario */
}
.accordion-button-download {
  display: flex;
  width: 273px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(--Color-5, #00AFFF);
  margin: 0 auto;
  display: block;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}
.accordion-button-download-text-inner {
  color: var(--Color-2, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.32px;
}
.btn-link {
  text-decoration: none;
  color: inherit;
}

.triangle-divider-accordion {
  position: relative;
  width: 100%;
  height: 0;
  border-left: 100vw solid transparent;
  border-bottom: 200px solid #08091D;
  margin-top: -1px;
  top: 48px;
}
.accordion-section-contaniner-main {
  position: relative;
  background-color: #ffffff;
  padding-bottom: 0;
  padding-top: 75px;
}
@media (max-width: 767px) {
  .accordion-button-download-container {
    width: 100%;
  }
  .accordion-button-download {
    width: 100%;
  }
  .accordion-content-title-inner,
  .Accordion-content-description-inner {
    font-size: 15px;
  }
  .module-number {
    color: #00AFFF;
    font-size: 16px;
  }
  .module-title {
    color: #07081C;
    font-size: 16px;
  }
  .triangle-divider-accordion {
    position: relative;
    width: 100%;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 100px solid #08091D;
    margin-top: -1px;

  }

  .accordion-title-desktop-version {
    color: var(--Fondo, #07081C);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 22px */
    letter-spacing: -0.4px;

  }

}