*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.header-container-aviso-legal{
    background-color: #0F1137;

}

.main-container{
    display: grid;
    min-height: 100dvh;
    grid-template-rows: auto 1fr auto;

}
.aviso-legal-main-title{
    color: #0F1137;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 52.8px */
letter-spacing: -0.96px;
}
.aviso-legal-description{
    color: var(--Fondo, #07081C);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */

}
img.logo-landing-product-management {
    width: 250px;
}
.button-aviso-legal{
    display: flex;
width: 142px;
height: 44px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;

}
.privacidad-main-title{
    color: var(--Fondo, #07081C);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */

}
.aviso-legal-description-list{
    color: var(--Fondo, #07081C);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%;
}
.aviso-legal-description-bold{
    color: var(--Fondo, #07081C);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%;

}
.aviso-legal-description-privacidad{
    color: var(--Fondo, #07081C);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */

}
.browsers-link-cookies{
    color: var(--Fondo, #00AFFF);
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 140%;
text-decoration: none;

}
.hidde-container{
    height: 400px;
}
.browsers-names-link-cookies{
    color: var(--Fondo, #07081C);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
@media (max-width: 767px){
    img.logo-landing-product-management {
        width: 120px;
    }
    .privacidad-main-title{
        color: var(--Fondo, #07081C);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 33.6px */
    
    }
    .button-aviso-legal{
        display: flex;
        width: 60px;
        height: 18.806px;
        padding: 4.274px;
        justify-content: center;
        align-items: center;
        gap: 4.274px;
        flex-shrink: 0;
        color: var(--Color-2, #FFF);
text-align: center;
font-family: Poppins;
font-size: 6.839px;
font-style: normal;
font-weight: 400;
line-height: 110%; /* 7.523px */

    
    }
    
    .aviso-legal-main-title{
        color: #0F1137;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 35.2px */
        letter-spacing: -0.64px;
    }
    .aviso-legal-description{
        color: var(--Fondo, #07081C);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 15.6px */
    
    }
    .footer-links {
        font-size: 13px;
        color: #ffffff;
        min-height: 0px;
      
    }

}

