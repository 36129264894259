*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body {
    overflow-x: hidden;
}

.thinkia-training-title {
    color: var(--Color-2, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: clamp(24px, 3vw, 32px);
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px;
    letter-spacing: -0.64px;
    margin-bottom: 30px;
}

.thinkia-training-description-title-inner {
    color: var(--Color-5, #00AFFF);
    font-family: Poppins;
    font-size: clamp(20px, 2.5vw, 24px);
    font-style: normal;
    font-weight: 600;
    line-height:  28.8px;
    letter-spacing: -0.528px;
    margin-bottom: 20px;
}

.thinkia-training-description-inner {
    color: var(--Color-2, #FFF);
    font-family: "Poppins";
    font-size: clamp(16px, 2vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: 23.4px;
}

.thinkia-training-description-inner.thinkia-training-bullets-description{
    list-style-type: disc; 
    margin-left: 20px;
    color: var(--Color-2, #FFF);
    font-family: "Poppins";
    font-size: clamp(16px, 2vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: 23.4px;
}

.thinkia-training-bullets-links{
    color: var(--Color-2, #FFF);
}

.thinkia-training-bullets-links:hover{
    color: var(--Color-5, #00AFFF);
}
.thinkia-training-img-container{
    padding-left: 140px !important;
}
.thinkia-training-text-container{
    padding-right: 140px !important;
}
.thinkia-training-logo-text{
    color: var(--Color-5, #00AFFF);
    text-align: center;
    font-family: Poppins;
    font-size: 44.958px;
    font-style: italic;
    font-weight: 700;
    line-height: 59.345px;
    letter-spacing: -0.899px;
    margin-bottom: 0px;

}
@media only screen and (max-width: 768px){
    .thinkia-training-text-container{
        padding-right: 0px !important;
    }
    .thinkia-training-img-container{
        padding-left: 0px !important;
    }
    .thinkia-training-logo-text{
        font-size: 30.958px;
        margin-bottom: 0px;

    }

}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .thinkia-training-img-container{
        padding-left: 60px !important;
    }
    .thinkia-training-text-container{
        padding-right: 60px !important;
    }

}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .thinkia-training-img-container{
        padding-left: 10px !important;
    }
    .thinkia-training-text-container{
        padding-right: 10px !important;
    }

}
@media only screen and (min-width: 800px) and (max-width: 999px) {
    .thinkia-training-img-container{
        padding-left: 10px !important;
    }
    .thinkia-training-text-container{
        padding-right: 10px !important;
    }

}
@media only screen and (min-width: 780px) and (max-width: 800px) {
    .thinkia-training-img-container{
        padding-left: 10px !important;
    }
    .thinkia-training-text-container{
        padding-right: 10px !important;
    }

}