.HeaderLandingProductManagementDevOps {
  .title-landing-product-management {
    color: var(--Color-2, #fff);
    text-align: start;
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 77.693px */
    letter-spacing: -1.413px;
  }
  img.logo-landing-product-management {
    width: 300px;
  }

  .content-landing-product-management span {
    color: #ffffff;
  }

  @media (max-width: 767px) {
    .header-landing-product-management {
      height: 342px;
    }

    .content-landing-product-management {
      position: relative;
      z-index: 2;
      right: 0px !important;
      top: 20px;
    }

    img.logo-landing-product-management {
      width: 230px !important;
    }

    .title-landing-product-management {
      text-align: center;
      font-size: 32px;
    }

    .form-landing-product-management {
      padding-top: 40px;
    }
    .header-landing-product-management {
      background-image: url("../assets/img/DevOps1.jpeg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 320px !important;
    }
  }

  .header-landing-product-management {
    background-image: url("../assets/img/DevOps1.jpeg");
    background-size: cover;
    /* background-position: -200px; */
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 818px;
  }

  .overlay-landing-product-management {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(56deg, #0a0c35 46.22%, #9ea1ed 167.84%);
    z-index: 1;
    opacity: 0.5;
  }

  .content-landing-product-management {
    position: relative;
    z-index: 2;
    right: 200px;
  }
}
