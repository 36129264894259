.title-landing-product-management {
  color: var(--Color-2, #FFF);
  text-align: start;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 77.693px */
  letter-spacing: -1.413px;

}

img.logo-landing-product-management {
  width: 300px;
}



.content-landing-product-management span {
  color: #ffffff;

}

@media (max-width: 767px) {
  .header-landing-product-management {
    height: 342px;
  }

  .content-landing-product-management {
    position: relative;
    z-index: 2;
    right: 0px !important;

  }

  img.logo-landing-product-management {
    width: 220px;
  }

  .title-landing-product-management {
    text-align: center;
    font-size: 32px;
  }

  .form-landing-product-management {
    padding-top: 40px;

  }
}

.header-landing-product-managementAM {
  background-image: url('../img/AgileManagement1.jpeg');
  background-size: cover;
  background-position: left left;
  background-repeat: no-repeat;
  position: relative;
  height: 818px !important;
}

.overlay-landing-product-management {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a224ca3;
  z-index: 1;
}


.content-landing-product-management {
  position: relative;
  z-index: 2;
  right: 50px;
}