.title-landing-product-management {
  color: var(--Color-2, #FFF);
  text-align: start;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -1.413px;
}
img.logo-landing-product-management {
  width: 300px;
}
.content-landing-product-management span {
  color: #ffffff;
}

@media (max-width: 767px) {
  .header-landing-product-management {
    height: 342px;
  }

  .content-landing-product-management {
    position: relative;
    z-index: 2;
    right: 0px !important;

  }

  img.logo-landing-product-management {
    width: 220px;
  }

  .title-landing-product-management {
    text-align: center;
    font-size: 32px;
  }

  .form-landing-product-management {
    padding-top: 40px;

  }
  .header-landing-product-managementIA {
    background-image: url('../img/IA_Generativa_1_flipped.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px !important;
    
  }
  
}
.header-landing-product-managementIA {
  background-image: url('../img/IA_Generativa_1_flipped.jpeg');
  background-size: cover;

  /* background-position: -200px; */
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 818px;
}
.overlay-landing-product-management {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(127deg, #07081C 46.22%, #212582 167.84%);
  z-index: 1;
  opacity: 0.6;
}
.content-landing-product-management {
  position: relative;
  z-index: 2;
  right: -50px;
}