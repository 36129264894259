.form-wrapper-landing-product-management {
  background: linear-gradient(127deg, #07081C 40%, #212582 120%);
}

.form-landing-product-management {
  background: transparent;
  color: white;
  font-size: 12px;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  width: 100%;
  height: auto;
}

.form-check-label {
  color: rgba(255, 255, 255, 0.32);
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 110%;
  /* 13.2px */
  letter-spacing: -0.24px;
}

.input-landing-product-management,
.textarea-landing-product-management {
  background-color: #1a1a3d;
  border: 1px solid rgba(255, 255, 255, 0.32);
  color: #ffffff80;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.32px;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
}

.input-landing-product-management {
  height: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background: transparent;
}

.textarea-landing-product-management {
  height: 150px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 4px;
  color: #ffffff80;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.32px;
  padding: 12px;
  resize: none;
  overflow-y: auto;
  transition: height 0.2s ease;
}

.textarea-landing-product-management::-webkit-scrollbar {
  width: 8px;
}

.textarea-landing-product-management::-webkit-scrollbar-thumb {
  background: #00aaff;
  border-radius: 4px;
}

.textarea-landing-product-management::-webkit-scrollbar-thumb:hover {
  background: #007bbf;
}

.textarea-landing-product-management::-webkit-scrollbar-track {
  background: #1a1a3d;
}

.input-landing-product-management::placeholder {
  color: #fff;
  background: transparent;
  border: none;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  letter-spacing: -0.32px;
}

.textarea-landing-product-management::placeholder {
  color: #ffffff50;
  border: none;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  letter-spacing: -0.32px;
}

.textarea-landing-product-management:focus,
.input-landing-product-management:focus {
  outline: none;
  background: transparent;
}

.form-check-container-landing-product {
  display: flex;
}

.custom-checkbox-landing-product-management {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 2px;
  background-color: transparent;
  appearance: none;
  position: relative;
}

.custom-checkbox-landing-product-management:checked {
  background-color: #00aaff;
}

.custom-checkbox-landing-product-management:checked::after {
  content: '';
  color: #FFFFFF;
  font-size: 12px;
  position: absolute;
  top: -2px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.btn {
  background-color: #00aaff;
  border: none;
}

.button-landing1-container {
  margin-bottom: 34px;
}

.btn-landing-product-management {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 48px;
  padding: 20px;
  border-radius: 2px;
  background: var(--Color-5, #00AFFF);
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 16px;
  border: none;
  transition: transform 0.2s ease;
}

.btn-landing-product-management:active {
  transform: scale(0.95);
}

.pulsating {
  animation: pulse 0.4s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.8);
  }
}

.title-form-landing-product-management {
  color: var(--Color-2, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.48px;
  max-width: 300px;
}

.input-landing-product-management-container {
  width: 100%;
}

.btn-landing-product-management p {
  margin: 0;
  font-family: 'Poppins';
  color: white;
}

.btn-landing-product-management svg {
  width: 18px;
  height: 18px;
  fill: white;
}

.btn-landing-product-management:hover {
  background-color: rgba(0, 175, 255, 0.5);
}



@media (max-width: 767px) {

  .button-landing1-container {
    margin-top: 10px;
    width: 100%;
  }

  .input-landing-product-management {
    font-size: 13px;
  }

  .form-landing-product-management {
    padding-bottom: 30px;
  }
}

.Logo-text-training {
  color: var(--Color-5, #00AFFF);
  text-align: center;
  font-family: Poppins;
  font-size: 35.966px;
  font-style: italic;
  font-weight: 700;
  line-height: 132%;
  /* 47.475px */
  letter-spacing: -0.719px;
}

.mobile-header {
  position: absolute;
  top: 16px;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 100;
}

.logo-landing-product-management {
  width: 120px;
}

.hamburger-menu {
  cursor: pointer;
}

.mobile-menu {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
}

.menu-list {
  // list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menu-list li {
  padding: 15px 0;
}

.menu-list li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Poppins';
}

@media (min-width: 768px) {

  .mobile-header,
  .mobile-menu {
    display: none;
  }

}