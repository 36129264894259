.hamburger-menu {
  cursor: pointer;
  width: 56px;
  height: 56px;
  display: grid;
  place-items: center;
  background: rgb(255 255 255 / 16%);
  border: 0;
  padding: 0;
  opacity: 0.6;
  scale: 0.7;
  border-radius: 8px;
  transition: 0.25s;
}
.nav-link{
  color: var(--Fondo, #07081C);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.36px;

}
.nav-link:hover {
  color: #00afff;
}

.hamburger-menu:hover {
  opacity: 1;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: scaleY(0.8) scaleX(1.2);
}

.burger-6.is-closed.hamRotate {
  transform: rotate(45deg) scale(1.2);
}

.line {
  fill: none;
  transition: 0.4s;
  stroke: #f9f9f9;
  stroke-width: 3;
  stroke-linecap: round;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.burger-6.is-closed.ham1 .top {
  stroke-dashoffset: -98px;
}

.burger-6.is-closed.ham1 .bottom {
  stroke-dashoffset: -138px;
}
