.section-thinkia {
    .footer-container {
        background-color: #08091D; /* Fondo oscuro */
        padding: 20px 0; /* Espaciado superior e inferior */
        color: white;
    }
    
    .footer-logo-container {
        margin-bottom: 10px; /* Espacio entre el logo y la línea */
        min-height: 175px;
    }
    
    .footer-logo {
        width: 129.998px;
    height: 132.238px; /* Ajusta el tamaño del logo */
    }
    
    .footer-line {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3); /* Color de la línea */
        margin: 10px 0; /* Espaciado superior e inferior */
    }
    
    .footer-links {
        font-size: 14px;
        color: #ffffff;
        min-height: 70px;
    }
    
    .footer-link {
        color: var(--Color-2, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
        text-decoration: none;
    }
    
    .footer-link:hover {
        text-decoration: none;
    }
    @media (max-width: 767px){
        .footer-logo {
            width: 129.998px;
            margin-top: 20px;
            
        }
    
    }
}
