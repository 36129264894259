
.faq-section-container {
    padding: 50px 0;
    position: relative;
    background-color: #ffffff;
    padding-bottom: 0;
}

.faq-left-col {
    text-align: start;
    background-color: #FFFFFF;
    padding: 20px;
    position: sticky;
    top: 20px;
    height: fit-content;
    margin-top: 9rem !important;
}

.faq-title-container-faq-section {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.faq-accent-svg-faq-section {
    position: absolute;
    bottom: 136px;
    left: 5px;
}

.faq-title-faq-section {
    font-family: 'Poppins';
    font-size: 56px;
    font-weight: 600;
    color: #07081C;
    margin: 0;
    line-height: 1.2;
}

.faq-right-col {
    padding: 20px;
    min-height: 400px;
}

.accordion-faq-section {
    min-height: 300px;
}

.accordion-item-faq-section {
    border-bottom: 1px solid #CCCCCC;
    padding: 5px 0; /* Ajustado */
    margin: 5px 0; /* Añadido */
}

.accordion-header-faq-section {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0; */
}

.accordion-header-faq-section h2 {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    color: #07081C;
    margin: 0;
    padding: 5px 0; /* Añadido */
}

.accordion-icon-faq-section {
    margin-left: 15px;
    transition: transform 0.3s ease;
    padding: 5px 0; /* Añadido */
}

.accordion-body-faq-section {
    padding-top: 15px;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.accordion-body-faq-section p {
    margin: 0;
}

.accordion-body-faq-section-inner {
    color: rgba(7, 8, 28, 0.64);
    font-family: Poppins;
    font-size: clamp(13px, 2vw, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.32px;
}

.triangle-divider-faq {
    position: relative;
    width: 100%;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 200px solid #08091D;
    margin-top: -1px;
    top: 2px;
}

@media (max-width: 767px) {
    .faq-left-col {
        position: relative;
        top: 0;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 0px;
        margin-top: 3rem !important;
    }

    .accordion-header-faq-section h2 {
        font-size: 16px;
    }

    .faq-title-faq-section {
        padding-left: 62px;
        position: relative;
        bottom: 10px;
        font-size: 20px;
    }

    .accordion-header-faq-section {
        justify-content: space-between;
    }

    .faq-section-container {
        padding: 1px 0;
    }

    .accordion-icon-faq-section {
        right: 0;
    }

    .faq-accent-svg-faq-section {
        position: absolute;
        bottom: 0px;
        left: 10px;
    }

    .accordion-body-faq-section {
        background: rgba(7, 8, 28, 0.04);
        padding-top: 0px;
    }

    .triangle-divider-faq {
        position: relative;
        width: 100%;
        height: 0;
        border-left: 100vw solid transparent;
        border-bottom: 100px solid #08091D;
        margin-top: -1px;
        top: 1px;
    }
}