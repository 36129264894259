.section-target-audience {
  background-color: #08091D;
  color: white;
  padding-top: 100px;
}

.title-section-target-audience {
  color: var(--Color-2, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;

  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.8px;

}

.description-list {
  list-style-type: disc !important;
  padding-left: 2rem !important;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.description-list-item {
  display: list-item !important;
  margin-bottom: 0.5rem;
  color: #666;
  padding-left: 0.5rem;
}

.prerequisites-section {
  margin-bottom: 2rem;
}

.prerequisites-section h3 {
  margin-bottom: 1rem;
}

.description-list li::before {
  display: none;
}

.subtitle-section-target-audience {
  color: var(--Color-5, #00AFFF);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.48px;
}

.description-section-target-audience {
  color: var(--Color-2, #FFF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: -0.32px;

}

.fourTargetAudience-separator {
  height: 55px;
}

.list-section-target-audience {
  list-style-type: disc !important;
  padding-left: 1.5rem !important;
}

.item-section-target-audience {
  display: list-item !important;
  color: #fff;
}

.list-section-target-audience li::before {
  display: none;
}

.highlight-section-target-audience {
  color: var(--Color-2, #FFF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: -0.32px;
}

.icon-text-targent-audience {
  margin-left: 30px;
  color: #F5F5F5;
  text-align: center;
  font-family: Poppins;
  font-size: clamp(14px, 2vw, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 17.6px */
  letter-spacing: -0.32px;
}

.description-section-target-audience {
  color: var(--Color-2, rgba(255, 255, 255, 0.64));
  font-family: Poppins;
  font-size: clamp(13px, 2vw, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.32px;
  /* Usa el color global */
}

.img-section-target-audience {
  height: 100%; 
  width: 100%;
  max-width: 100%;
  object-fit: contain; 
  border-radius: 3px;
}

.row-section-target-audience {
  display: flex;
  align-items: stretch; 
  gap: 20px; 
}

.text-section-target-audience,
.image-section-target-audience {
    flex: 1; 
}

.icons-section-target-audience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-item-section-target-audience {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-item-section-target-audience img {
  width: 36px;
  height: 36px;
}

.icon-item-section-target-audience span {
  font-family: 'Poppins';
  font-size: 16px;
  color: #F5F5F5;
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .fourTargetAudience-separator {
    height: 1px;
  }

}

@media (max-width: 767px) {

  .text-section-target-audience,
  .image-section-target-audience {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .row-section-target-audience {
    flex-direction: column; 
    align-items: center; 
  }
  .img-section-target-audience {
    max-width: 100%;
    height: auto;
    border-radius: 3px;
    object-fit: cover;
  }

  .highlight-section-target-audience {
    font-size: 13px;

  }

  .section-target-audience {
    padding: 20px 0;
  }

  .icon-item-section-target-audience img {
    width: 20px;
    height: 20px;
  }

  .icon-text-targent-audience {
    margin-left: 60px;

  }

  .icon-item-section-target-audience span {
    font-size: 14px;

  }

  .title-section-target-audience {
    font-size: 16px;
  }

  .subtitle-section-target-audience {
    color: var(--Color-5, #00AFFF);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 26.4px */
    letter-spacing: -0.48px;
  }

  .description-section-target-audience {
    font-size: 15px;


  }


}